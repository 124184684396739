import React from "react"
import Layout from "../components/layout"
import "../scss/index.scss";

const NotFoundPage = () => (
    <Layout>
        <div className="404-us-page">
            <div className="container-max-width">
                <div className="inner-container">
                    <h1>NOT FOUND</h1>
                    <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
                </div>
            </div>
        </div>
    </Layout>
)

export default NotFoundPage
